import React from "react";
import "./App.css";
import BulletPoints from "./scripts/components/bulletPoints.js";
import AppointmentEtiquette from "./scripts/components/appointmentEtiquette.js";
import Contact from "./scripts/components/contact.js";
import Header from "./scripts/components/header.js";
import castleknockImage from "./assets/shopImages/castleknock.jpg";
import arklowImage from "./assets/shopImages/arklow.jpg";
import blessingtonImage from "./assets/shopImages/blessington.jpg";

function App() {
 
    return (
        <div className="App">
            <Header />
        </div>
    );
}

export default App;
