import React, { Component } from "react";
import separatorLine from "../../assets/separatorLine.png";
import logo from "../../assets/logo.svg";

export default function Header() {
    return (
        <div className="header">
            <div className="content_container">
                <div className="header_section">
                    <img src={logo} className="App-logo" alt="logo" />
                    <h1>Website currently under redesign</h1>
                </div>
                <div className="text_content">
                    <p>
                        We are currently making some improvements to our website, and we will be live again soon.
                    </p>
                    <p>
                        In the meantime, if you would like to speak with us about a new website orapp, please call us on 
                        <span style={{ fontWeight: "bold", color: "#b6246c" }}>
                            {" "}
                            012118550{" "}
                            </span>
or email us at 
                        <span style={{ fontWeight: "bold", color: "#b6246c" }}>
                            {" "}
                            info@digigrow.ie{" "}
                            </span>

                    </p>

                    <p>
                        Thank you for your patience.
                    </p>

                </div>
            </div>
        </div>
    );
}
